<template>
  <div class="roles-permission-mobile-view">
    <v-navigation-drawer permanent floating width="100%">
      <v-list dense nav>
        <v-list-item
          v-for="(key, i) in roles"
          :key="i"
          link
          @click="showSelectedPermissions(key)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ $t(key) }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <span
              v-if="showNoPermissionMsg(key)"
              class="caption no-permission-indicator"
              >{{ $t("noPermissionMessage") }}</span
            >
            <v-icon>{{ "mdi-chevron-right" }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-dialog
      v-model="dialog"
      fullscreen
      max-width="500px"
      content-class="roles-and-permission-dialog"
    >
      <v-card>
        <v-card-title class="px-3">
          <v-row>
            <v-col cols="9"
              ><v-btn
                icon
                aria-label="close"
                class="pr-2"
                @click="dialog = false"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <span class="text-h6 font-weight-medium">{{
                $t(selectedRole)
              }}</span></v-col
            >
            <v-col cols="3" class="px-0"
              ><div v-if="showActionButtons" style="float: right">
                <v-btn icon @click="editRole">
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  icon
                  v-if="
                    (!roleUserCountMap[selectedRole] ||
                      roleUserCountMap[selectedRole] === 0) &&
                    checkPermission('manageRoles')
                  "
                  @click="deleteRole"
                >
                  <v-icon dark>mdi-delete-outline</v-icon> </v-btn
                ><v-tooltip
                  v-else-if="checkPermission('manageRoles')"
                  :right="!$vuetify.breakpoint.smAndDown"
                  :bottom="$vuetify.breakpoint.smAndDown"
                  max-width="400"
                  class="pa-0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <a v-bind="attrs" v-on="on">
                      <v-btn icon disabled @click="deleteRole">
                        <v-icon dark>mdi-delete-outline</v-icon>
                      </v-btn></a
                    ></template
                  >
                  <span>
                    There are {{roleUserCountMap[selectedRole] ? roleUserCountMap[selectedRole] : 0}} users in this role. Reassign the current users to delete this role.
                  </span
                ></v-tooltip></div
            ></v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0">
          <v-alert
            v-if="showNoPermissionAlert"
            type="warning"
            color="#AD4C0B"
            icon="mdi-alert-outline"
            text
            class="caption font-weight-regular py-3 px-5"
            height="44px"
          >
            {{ $t("noPermissionEnabledWarning") }}
          </v-alert>
          <RolesAndPermissionTreeView
            v-for="(role, i) in roles"
            v-show="selectedRole === role"
            :key="i"
            ref="treeView"
            :items="rolesAndPermissions[role]"
            :role="role"
            @updatePermissions="updatePermissions"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RolesAndPermissionTreeView from "./RolesAndPermissionTreeView.vue";
import rolesAndPermissionsDefaults from "@/static/rolesAndPermissionsDefaults.json";
import UserDataMixin from "@/mixins/UserData";
export default {
  components: {
    RolesAndPermissionTreeView: () =>
      import("./RolesAndPermissionTreeView.vue"),
  },
  mixins: [UserDataMixin],
  props: {
    rolesAndPermissions: {
      type: Object,
      default: () => {},
    },
    roleUserCountMap: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      selectedRole: null,
      defaultRoles: rolesAndPermissionsDefaults.defaultRoles,
    };
  },
  computed: {
    showActionButtons() {
      return !this.defaultRoles.includes(this.selectedRole);
    },
    roles() {
      const rolesArray = Object.keys(this.rolesAndPermissions);
      if (rolesArray.includes("admin")) {
        const index = rolesArray.indexOf("admin");
        rolesArray.splice(index, 1);
        rolesArray.unshift("admin");
      }
      return rolesArray;
    },
    showNoPermissionAlert() {
      if (this.selectedRole) {
        let showAlert = true;
        const currentItem = this.rolesAndPermissions[this.selectedRole];
        currentItem.forEach((element) => {
          if (element.activeNodes && element.activeNodes.length) {
            showAlert = false;
          }
        });
        return showAlert;
      }
      return false;
    },
  },

  methods: {
    showSelectedPermissions(key) {
      this.dialog = true;
      this.selectedRole = key;
    },
    close() {
      this.dialog = false;
    },
    updatePermissions(updateObject) {
      this.$emit("updatePermissions", updateObject);
    },
    showNoPermissionMsg(role) {
      const currentItem = this.rolesAndPermissions[role];
      let count = 0;
      currentItem.forEach((element) => {
        if (element.activeNodes && element.activeNodes.length) {
          count++;
        }
      });
      return count > 0 ? false : true;
    },
    editRole() {
      this.$emit("editRole", this.selectedRole);
    },
    deleteRole() {
      this.$emit("deleteRole", this.selectedRole);
      this.dialog = false;
      this.selectedRole = null;
    },
  },
};
</script>

<style lang="scss">
@import "../../../scss/components/roles-and-permissions.scss";
.roles-permission-mobile-view {
  width: 100%;
}
.no-permission-indicator {
  color: #c35309;
  padding-top: 2px;
}
</style>
