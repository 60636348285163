var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "roles-permission-mobile-view" },
    [
      _c(
        "v-navigation-drawer",
        { attrs: { permanent: "", floating: "", width: "100%" } },
        [
          _c(
            "v-list",
            { attrs: { dense: "", nav: "" } },
            _vm._l(_vm.roles, function (key, i) {
              return _c(
                "v-list-item",
                {
                  key: i,
                  attrs: { link: "" },
                  on: {
                    click: function ($event) {
                      return _vm.showSelectedPermissions(key)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v(_vm._s(_vm.$t(key)))])],
                    1
                  ),
                  _c(
                    "v-list-item-icon",
                    [
                      _vm.showNoPermissionMsg(key)
                        ? _c(
                            "span",
                            { staticClass: "caption no-permission-indicator" },
                            [_vm._v(_vm._s(_vm.$t("noPermissionMessage")))]
                          )
                        : _vm._e(),
                      _c("v-icon", [_vm._v(_vm._s("mdi-chevron-right"))]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: "",
            "max-width": "500px",
            "content-class": "roles-and-permission-dialog",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "px-3" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "9" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "pr-2",
                              attrs: { icon: "", "aria-label": "close" },
                              on: {
                                click: function ($event) {
                                  _vm.dialog = false
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "text-h6 font-weight-medium" },
                            [_vm._v(_vm._s(_vm.$t(_vm.selectedRole)))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "px-0", attrs: { cols: "3" } },
                        [
                          _vm.showActionButtons
                            ? _c(
                                "div",
                                { staticStyle: { float: "right" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: { click: _vm.editRole },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-pencil-outline"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  (!_vm.roleUserCountMap[_vm.selectedRole] ||
                                    _vm.roleUserCountMap[_vm.selectedRole] ===
                                      0) &&
                                  _vm.checkPermission("manageRoles")
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "" },
                                          on: { click: _vm.deleteRole },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { dark: "" } },
                                            [_vm._v("mdi-delete-outline")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.checkPermission("manageRoles")
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          staticClass: "pa-0",
                                          attrs: {
                                            right:
                                              !_vm.$vuetify.breakpoint
                                                .smAndDown,
                                            bottom:
                                              _vm.$vuetify.breakpoint.smAndDown,
                                            "max-width": "400",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "a",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "a",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              disabled: "",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.deleteRole,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  dark: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-delete-outline"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2048513228
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " There are " +
                                                _vm._s(
                                                  _vm.roleUserCountMap[
                                                    _vm.selectedRole
                                                  ]
                                                    ? _vm.roleUserCountMap[
                                                        _vm.selectedRole
                                                      ]
                                                    : 0
                                                ) +
                                                " users in this role. Reassign the current users to delete this role. "
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-0" },
                [
                  _vm.showNoPermissionAlert
                    ? _c(
                        "v-alert",
                        {
                          staticClass: "caption font-weight-regular py-3 px-5",
                          attrs: {
                            type: "warning",
                            color: "#AD4C0B",
                            icon: "mdi-alert-outline",
                            text: "",
                            height: "44px",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("noPermissionEnabledWarning")) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._l(_vm.roles, function (role, i) {
                    return _c("RolesAndPermissionTreeView", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectedRole === role,
                          expression: "selectedRole === role",
                        },
                      ],
                      key: i,
                      ref: "treeView",
                      refInFor: true,
                      attrs: {
                        items: _vm.rolesAndPermissions[role],
                        role: role,
                      },
                      on: { updatePermissions: _vm.updatePermissions },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }